import imageOne from '../images/Spaceship pictures/ship001.png'
import imageTwo from '../images/Spaceship pictures/ship002.png'
import imageThree from '../images/Spaceship pictures/ship003.png'
import imageFour from '../images/Spaceship pictures/ship004.png'
import imageFive from '../images/Spaceship pictures/ship005.png'
import imageSix from '../images/Spaceship pictures/ship006.png'
import imageSeven from '../images/Spaceship pictures/ship007.png'
import imageEight from '../images/Spaceship pictures/ship008.png'
import imageNine from '../images/Spaceship pictures/ship009.png'
import imageTen from '../images/Spaceship pictures/ship0010.png'
import imageEleven from '../images/Spaceship pictures/ship0011.png'
import imageTwelve from '../images/Spaceship pictures/ship0012.png'
import imageThirteen from '../images/Spaceship pictures/ship0013.png'
import imageFourteen from '../images/Spaceship pictures/ship0014.png'
import imageFifteen from '../images/Spaceship pictures/ship0015.png'
import imageSixteen from '../images/Spaceship pictures/ship0016.png'
import imageSeventeen from '../images/Spaceship pictures/ship0017.png'
import imageEighteen from '../images/Spaceship pictures/ship0018.png'
import imageNineteen from '../images/Spaceship pictures/ship0019.png'
import imageTwenty from '../images/Spaceship pictures/ship0020.png'

export const ShipsData:any[]=[
    {
        
    },
    {
        id: "62038854a2f8cfdb9442b889",
        price: 0,
        index: "SHIP001",
        firerate:5,
        damage:20,
        bullet:1,
        img: imageOne,
    },
    {
        id: "62038898a2f8cfdb9442b88b",
        price: 280000,
        index: "SHIP002",
        firerate:5,
        damage:20,
        bullet:1,
        img: imageTwo,

    },
    {
        id: "620389716b7e7c24bb53fc09",
        price: 38000,
        index: "SHIP003",
        firerate:5,
        damage:20,
        bullet:1,
        img: imageThree,

    },
    {
        id: "6203897c6b7e7c24bb53fc0c",
        price: 480000,
        index: "SHIP004",
        firerate:5,
        damage:20,
        bullet:1,
        img: imageFour,

    },
    {
        id: "620389ca6b7e7c24bb53fc0f",
        price: 580000,
        index: "SHIP005",
        firerate:5,
        damage:20,
        bullet:1,
        img: imageFive,

    },
    {
        id: "620389da6b7e7c24bb53fc12",
        price: 700000,
        index: "SHIP006",
        firerate:5,
        damage:20,
        bullet:1,
        img: imageSix,

    },
    {
        id: "620389f46b7e7c24bb53fc15",
        price: 820000,
        index: "SHIP007",
        firerate:5,
        damage:20,
        bullet:1,
        img: imageSeven,

    },
    {
        id: "62038a556b7e7c24bb53fc18",
        price: 940000,
        index: "SHIP008",
        firerate:5,
        damage:20,
        bullet:1,
        img: imageEight,

    },
    {
        id: "62038a5e6b7e7c24bb53fc1b",
        price: 1060000,
        index: "SHIP009",
        firerate:5,
        damage:20,
        bullet:1,
        img: imageNine,

    },
    {
        id: "62038cb6d68e668d36ff0996",
        price: 1180000,
        index: "SHIP0010",
        firerate:5,
        damage:20,
        bullet:1,
        img: imageTen,

    },
    {
        id: "62038cf7d68e668d36ff0999",
        price: 1330000,
        index: "SHIP0011",
        firerate:5,
        damage:20,
        bullet:1,
        img: imageEleven,

    },
    {
        id: "62038d65d68e668d36ff099c",
        price: 1480000,
        index: "SHIP0012",
        firerate:5,
        damage:20,
        bullet:1,
        img: imageTwelve,

    },
    {
        id: "62038d6fd68e668d36ff099f",
        price: 1630000,
        index: "SHIP0013",
        firerate:5,
        damage:20,
        bullet:1,
        img: imageThirteen,

    },
    {
        id: "62038d7ad68e668d36ff09a2",
        price: 1780000,
        index: "SHIP0014",
        firerate:5,
        damage:20,
        bullet:1,
        img: imageFourteen,

    },
    {
        id: "62038d94d68e668d36ff09a5",
        price: 1930000,
        index: "SHIP0015",
        firerate:5,
        damage:20,
        bullet:1,
        img: imageFifteen,

    },
    {
        id: "62038da2d68e668d36ff09a8",
        price: 2230000,
        index: "SHIP0016",
        firerate:5,
        damage:20,
        bullet:1,
        img: imageSixteen,

    },
    {
        id: "62038daed68e668d36ff09ab",
        price: 2530000,
        index: "SHIP0017",
        firerate:5,
        damage:20,
        bullet:1,
        img: imageSeventeen,

    },
    {
        id: "62038db8d68e668d36ff09ae",
        price: 2830000,
        index: "SHIP0018",
        firerate:5,
        damage:20,
        bullet:1,
        img: imageEighteen,

    },
    {
        id: "62038dc1d68e668d36ff09b1",
        price: 3130000,
        index: "SHIP0019",
        firerate:5,
        damage:20,
        bullet:1,
        img: imageNineteen,

    },
    {
        id: "62038dcbd68e668d36ff09b4",
        price: 3430000,
        index: "SHIP0020",
        firerate:5,
        damage:20,
        bullet:1,
        img: imageTwenty,

    }
]