import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import WalletConnectProvider from "@walletconnect/web3-provider";
import EthContract from "web3-eth-contract";
import axios from "axios";
import env from "../../environment"


import {
  BATTLE_SHIP_CONTRACT_ABI,
  BATTLE_SHIP_CONTRACT_ADDRESS,
} from "../../contract/BattleShip";
import {
  ERC20_CONTRACT_ABI,
  ERC20_CONTRACT_ADDRESS,
} from "../../contract/ERC20";

import { RootStateType } from "../store";

export type StateType = {
  web3: null | Web3;
  address: null;
  battleShipContract: null | EthContract.Contract;
  erc20Contract: null | EthContract.Contract;
  account: string;
  web3LoadingErrorMessage: string;
  web3Loading: boolean;
  ErrorMessage: string;
  provider: null;
  data: any;
};

type Web3ConnectPayloadType = {
  web3: Web3;
  account: string;
  web3Loading: boolean;
  battleShipContract: EthContract.Contract;
  erc20Contract: EthContract.Contract;
  web3LoadingErrorMessage: any;
};

export const initialState: StateType = {
  web3: null,
  battleShipContract: null,
  erc20Contract: null,
  address: null,
  account: null || "",
  web3LoadingErrorMessage: "",
  web3Loading: false,
  ErrorMessage: "",
  provider: null,
  data: null,
};

export const loadBlockchain = createAsyncThunk(
  "loadwalletcoonnect",
  async (_, thunkAPI) => {
    try {
      if (Web3.givenProvider) {
        await Web3.givenProvider.enable();
        const web3 = new Web3(Web3.givenProvider);
        let account: any = await web3.eth.getAccounts();
        const battleShipContract: EthContract.Contract = new web3.eth.Contract(
          BATTLE_SHIP_CONTRACT_ABI as AbiItem[],
          BATTLE_SHIP_CONTRACT_ADDRESS
        );

        const erc20Contract: EthContract.Contract = new web3.eth.Contract(
          ERC20_CONTRACT_ABI as AbiItem[],
          ERC20_CONTRACT_ADDRESS
        );

        console.log("contract",erc20Contract)

        account = account[0];
        return {
          web3,
          account,
          battleShipContract,
          erc20Contract,
        };
      } else {
        console.log("error connecting to metamask");
        return {
          web3LoadingErrorMessage: "error connecting to metamask",
        };
      }
    } catch (err) {
      console.log(err);
    }
  }
);

// Connect with Wallet of users choice
export const loadWalletConnect = createAsyncThunk(
  "LoadWalletConnect",
  async (_, thunkAPI) => {
    try {
      const provider = new WalletConnectProvider({
        rpc: {
          4: "https://rinkeby.infura.io/v3/9043c5907b4f4696a35189799c013dee",
        },
        chainId: 4,
      });
      console.log("Provider", provider);
      if (provider) {
        await provider.enable();
        const web3 = new Web3(provider as any);
        console.log("web3 state", web3);
        let account: any = await web3.eth.getAccounts();
        const battleShipContract: EthContract.Contract = new web3.eth.Contract(
          BATTLE_SHIP_CONTRACT_ABI as AbiItem[],
          BATTLE_SHIP_CONTRACT_ADDRESS
        );

        const erc20Contract: EthContract.Contract = new web3.eth.Contract(
          ERC20_CONTRACT_ABI as AbiItem[],
          ERC20_CONTRACT_ADDRESS
        );
        account = account[0];

        return {
          web3,
          account,
          battleShipContract,
          erc20Contract,
        };
      } else {
        return {
          web3LoadingErrorMessage:
            "Please install an Ethereum-compatible browser or extension like MetaMask to use this dApp!",
        };
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const getAllShips = createAsyncThunk("getAllShips", async () => {
  const response = await axios.get(`${env.BACKEND_BASE_URL}/get_all_ships`);
  console.log(response);
  return response.data.data;
});

export const logOutBlockchain = createAsyncThunk(
  "logOutBlockchain",
  async (_, thunkAPI) => {
    try {
      const web3 = null;

      let account = null;

      const battleShipContract = null;
      const erc20Contract = null;

      return {
        web3,
        account,
        battleShipContract,
        erc20Contract,
      };
    } catch (err) {
      console.log(err);
    }
  }
);

const web3ConnectSlice = createSlice({
  name: "Web3Connect",
  initialState,
  reducers: {},
  extraReducers: {
    [loadWalletConnect.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<Web3ConnectPayloadType>
    ) => {
      state.web3 = payload?.web3;
      state.account = payload?.account;
      state.web3Loading = false;
      state.battleShipContract = payload?.battleShipContract;
      state.erc20Contract = payload?.erc20Contract;

      state.web3LoadingErrorMessage = payload?.web3LoadingErrorMessage;
    },
    [loadBlockchain.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<Web3ConnectPayloadType>
    ) => {
      console.log("payload>>>", payload);
      state.web3 = payload?.web3;
      state.account = payload?.account;
      state.web3Loading = false;
      state.battleShipContract = payload?.battleShipContract;
      state.erc20Contract = payload?.erc20Contract;

      state.web3LoadingErrorMessage = payload?.web3LoadingErrorMessage;
    },

    [logOutBlockchain.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<Web3ConnectPayloadType>
    ) => {
      state.web3 = payload?.web3;
      state.account = payload?.account;
      state.web3Loading = false;
      state.battleShipContract = payload?.battleShipContract;
      state.erc20Contract = payload?.erc20Contract;
      state.web3LoadingErrorMessage = payload?.web3LoadingErrorMessage;
    },
    [getAllShips.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<Web3ConnectPayloadType>
    ) => {
      state.data = payload;
    },
    [getAllShips.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<Web3ConnectPayloadType>
    ) => {
      console.log("payload>>>", payload);
      state.data = payload;
    },
  },
});

export const web3Reducer = web3ConnectSlice.reducer;
