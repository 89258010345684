import EthContract from 'web3-eth-contract'

export const addShip = async (contract: EthContract.Contract,
    account: string,
    price: number) => {
    try {
        const result = await contract.methods.approve(price).send({ from: account })
        return result
    } catch (err) {
        console.log('Error in getVaultWeb3', err)
        return err
    }
}

export const grantRole = async (contract: EthContract.Contract,
    role: string,
    account: string,
    address: string) => {
    try {
        const result = await contract.methods.grantRole(role, address).send({ from: account })
        return result
    } catch (err) {
        console.log('Error in getVaultWeb3', err)
        return err
    }
}

export const rentShip = async (contract: any,
    id: string,
    account: string) => {
        console.log("id",id)
    try {
        const result = await contract.methods.rentShip(id, account).send({ from: account })
        return result
    } catch (err) {
        console.log('Error in getVaultWeb3', err)
        return err
    }
}