import EthContract from 'web3-eth-contract'

export const balanceOf = async (contract: any,
  address: string) => {
  try {
    console.log("contract", contract)
    const result = await contract?.methods.balanceOf(address).call()
    return result
  } catch (err) {
    console.log('Error in getVaultWeb3', err)
    return err
  }
}



export const totalSupply = async (contract: any) => {
  try {
    console.log("contract", contract)
    const result = await contract?.methods.totalSupply().call()
    return result
  } catch (err) {
    console.log('Error in getVaultWeb3', err)
    return err
  }
}



export const allowance = async (contract: any, owner: string, spender: string) => {
  try {
    console.log("contract", contract)
    const result = await contract?.methods.allowance(owner, spender).call()
    return result
  } catch (err) {
    console.log('Error in getVaultWeb3', err)
    return err
  }
}



