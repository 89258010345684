import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";

import { useAppDispatch, useAppSelector } from "../../redux/store";
import axios from "axios";

import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";

import Button from "@mui/material/Button";
import { rentShip } from "../../redux/slices/battleShip/index";

import Modal from "@material-ui/core/Modal";
import ConnectModal from "../../modal/connectModal";

import img from "../../assets/img.png";

import env from "../../environment";

import Snackbar from "@mui/material/Snackbar";
import {approve,totalSupply, allowance} from '../../redux/slices/erc20/'
import {
  BATTLE_SHIP_CONTRACT_ADDRESS,
} from "../../contract/BattleShip";

import {ShipsData} from "../../data/ShipsData"



type CardProps = {
  data: any;
};
const config = {
  headers: {
    "Content-Type": "application/json",
  },
};
const Cards: React.FC<CardProps> = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [userResponse, setUserResponse] = useState(false);

 

  const [openConnect, setOpenConnect] = useState(false);

  const handleConnectOpen = () => {
    setOpenConnect(true);
  };

  const handleConnectClose = () => {
    setOpenConnect(false);
  };


  const { web3, battleShipContract, account, erc20Contract } = useAppSelector(
    (state) => state.web3Connect
  );
  console.log(account);

  const purchaseShipsObj = {
    user_address: (account.toLowerCase()),
    index: data?.index,
  };
  // console.log(purchaseShipsObj);

  const handleRent = async () => {
    try {
      setOpen(false)
      console.log("datadatadata",ShipsData[data?.id].firerate)

      let totalAmount = await totalSupply(erc20Contract);
      let allowanceAmount = await allowance(erc20Contract, account, BATTLE_SHIP_CONTRACT_ADDRESS);
    
      let checkUserPurchased:any = await axios.post(
        env.BACKEND_BASE_URL + "/purchased",
         purchaseShipsObj ,
        config
      );
      checkUserPurchased = checkUserPurchased.data
      if(!checkUserPurchased.status) throw checkUserPurchased
      if(allowanceAmount < (1000 * 10e17)) {
        let approval = await approve(erc20Contract, BATTLE_SHIP_CONTRACT_ADDRESS,account,  totalAmount)

      }

      let res = await rentShip(
        battleShipContract,
        data.id,
        account.toLowerCase()
      );
      console.log(res?.status);
      if(res.status) {

      let purchaseShips = await axios.post(
        env.BACKEND_BASE_URL + "/purchase_ship",
         purchaseShipsObj ,
        config
      );

      
      }
    } catch (error:any) {
      let message = error.error
      console.log("error", message);

      setErrorMessage(message)
      setOpen(true)

    }
  };

  let userAddress = account;
  console.log(userAddress);
  useEffect(() => {
    if (web3 !== null) {
      getUserAddress();
    }
  }, [web3]);

  const getUserAddress = async () => {
    let response = await axios.get(
      env.BACKEND_BASE_URL + "/get_player/" + (userAddress).toLowerCase()
    );
    if (response?.data.status == "Failed") {
      setUserResponse(false);
    } else {
      setUserResponse(true);
    }
    console.log("response",response.data.status);
  };

console.log("data",data)
  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        message={errorMessage}
      />
     
      <Modal
        open={openConnect}
        onClose={handleConnectClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ConnectModal setOpenConnect={setOpenConnect} />
      </Modal>
      <Card className="cards" >
        <CardContent>
          <img
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "1.9rem",
              width: "300px",
              height: "400px"
            }}
            src={ShipsData[data?.id]?.img}
            alt=""
          />
          <div style={{ display: "flex", justifyContent: "space-between " }}>
            <Typography gutterBottom variant="h5" component="div">
              {data?.index}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {ShipsData[data?.id]?.price}
            </Typography>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between " }}>
            <Typography gutterBottom variant="h5" component="div">
              FIRE RATE
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {ShipsData[data?.id]?.firerate}
            </Typography>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between " }}>
            <Typography gutterBottom variant="h5" component="div">
              DAMAGE
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {ShipsData[data?.id]?.damage}
            </Typography>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between " }}>
            <Typography gutterBottom variant="h5" component="div">
              BULLET
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {ShipsData[data?.id]?.bullet}
            </Typography>
          </div>
          {web3 && userResponse ? (
            <Button onClick={handleRent}>Purchase</Button>
          ) : !userResponse && web3 !== null ? (
            "You are Not registered"
          ) : (
            <Button onClick={handleConnectOpen}>Connect Wallet</Button>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default Cards;
