const environment = {
  BACKEND_BASE_URL: "https://api.cyberbattlestudios.com",
//   BACKEND_BASE_URL: "http://localhost:8080",
};

/*if(process.env.REACT_APP_ENV==="development"){
    environment.BACKEND_BASE_URL= "http://localhost:8080"
}


if(process.env.REACT_APP_ENV==="production"){
    environment.BACKEND_BASE_URL= "https://owlnew.buildmydapp.co"
}


if(process.env.REACT_APP_ENV==="staging"){
    environment.BACKEND_BASE_URL= "https://api.illuminatiowls.com"
}

*/
export default environment;
