import { createSlice } from "@reduxjs/toolkit";

export const getAllShipsSlice = createSlice({
  name: "getAllShips",
  initialState: {
    result: [],
    loading: false,
    error: false,
    errorMessage: "",
  },
  reducers: {
    getAllShipsFetch: (state) => {
      state.loading = true;
    },
    getAllShipsSuccess: (state, action) => {
      state.result = action.payload;
      state.loading = false;
    },
    getAllShipsFailure: (state, action) => {
      state.errorMessage = action.payload.error;
      state.loading = false;
    },
  },
});

export const { getAllShipsFetch, getAllShipsSuccess, getAllShipsFailure } =
  getAllShipsSlice.actions;
export const getAllShipsReducers = getAllShipsSlice.reducer;
