import React, { useEffect, useState } from "react";
import "./navbar.css";
import AppBar from "@mui/material/AppBar";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";
import { useAppDispatch, useAppSelector } from "../redux/store";

import Modal from "@material-ui/core/Modal";
import ConnectModal from "../modal/connectModal";

import { logOutBlockchain } from "../redux/slices/web3ConnectSlice";
import { balanceOf } from '../redux/slices/erc20'
export default function Navbars() {
  const dispatch = useAppDispatch();

  const [openConnect, setOpenConnect] = useState(false);
  const [userBalance, setUserBalance] = useState(false);

  const handleConnectOpen = () => {
    setOpenConnect(true);
  };

  const handleLogout = () => {
    dispatch(logOutBlockchain());
  };

  const handleConnectClose = () => {
    setOpenConnect(false);
  };

  const { web3, account, erc20Contract } = useAppSelector((state) => state.web3Connect);

  const handleBalnce = async () => {
    try {
      let balance = await balanceOf(erc20Contract, account)
      balance = (balance / 10e17).toFixed(2)
      console.log("balance", balance)
      setUserBalance(balance)
    }
    catch (error) {

    }
  }
  console.log("account", account)
  useEffect(() => {

    if (account) {
      handleBalnce()

    }

  }, [account, erc20Contract])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            {/* <MenuIcon /> */}
          </IconButton>
          <Typography className="navbarBrand" variant="h6" sx={{ flexGrow: 1 }}>
            Cyber Battle
          </Typography>

          <h5 className="balance me-4 mt-1">Balance: {userBalance ? userBalance : "0"}</h5>
          {web3 ? (
            <div>
              <button
                className=" "
                style={{
                  borderRadius: "10px",
                  padding: "0.5rem 2rem",
                  outline: "none",
                  border: "none",
                  cursor: "pointer",
                  boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px",
                }}
                onClick={() => {
                  handleLogout();
                }}
              >
                Logout
              </button>
            </div>
          ) : (
            <div>
              <button
                className="connectBtn"
                style={{
                  borderRadius: "10px",
                  padding: "0.7rem 2rem",
                  outline: "none",
                  border: "none",
                  cursor: "pointer",
                  boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px",
                }}
                onClick={handleConnectOpen}
              >
                Connect Wallet{" "}
              </button>
              <Modal
                open={openConnect}
                onClose={handleConnectClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                <ConnectModal setOpenConnect={setOpenConnect} />
              </Modal>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
