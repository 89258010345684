import React, { useEffect, useState } from "react";
import { getAllShips } from "../../redux/slices/web3ConnectSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import "./getShips.css";
import { Grid } from "@material-ui/core";
import Cards from "./cards";

const Ships  = () => {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state) => state.web3Connect);

   
  useEffect(() => {
    dispatch(getAllShips());
  }, [dispatch]);

   

  return (
    <>
      <div className="cardMain mt-5 text-md-center">
        <Grid container>
          {data?.map((item: any) => (
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Cards data={item}  />
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
};

export default Ships;
