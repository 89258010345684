import React, { useEffect } from "react";
import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Ships from "./components/ships";

import Navbars from "./navbar/Navbars";

function App() {
  return (
    <Router>
      <Navbars />
      <Routes>
        <Route path="/" element={<Ships />} />
      </Routes>
    </Router>
  );
}

export default App;
