import { combineReducers } from "redux";
import { web3Reducer } from "./slices/web3ConnectSlice";
import { getAllShipsReducers } from "./slices/getAllShipsSlice";

const parentReducer = combineReducers({
  web3Connect: web3Reducer,
  getAllShips: getAllShipsReducers,
});

export default parentReducer;
