import EthContract from 'web3-eth-contract'

export const approve = async (contract: any,
    spender: string,
    account: string,
    amount: number)=> {
    try {

      console.log(
        "approva function" ,
        spender,
        account,
        amount
      )
      const result = await contract.methods.approve(spender,amount).send({ from: account })
      return result
    } catch (err) {
      console.log('Error in getVaultWeb3', err)
      return err
    }
  }


